var validate = require('validate.js');

/* General */

// Return article for a given word
window.article = word => {
  let first_letter = word.charAt(0).toLowerCase();
  let vowels = ['a', 'e', 'i', 'o', 'u'];

  return vowels.includes(first_letter) ? 'an' : 'a';
}

// Convert data to URL params
window.data_to_params = (data, path = '') => {
  const parameters = [].concat(...Object.entries(data).map(([key, value]) => {
                                let encoded_key = encodeURIComponent(key);
                                let prefix = path ? `${path}[${encoded_key}]` : `${encoded_key}`;

                                if (typeof value === 'object') {
                                  return data_to_params(value, prefix);
                                } else {
                                  return !value ? '' : `${prefix}=${encodeURIComponent(value)}`;
                                }
                              }));
  const query_string = parameters.join('&');

  return query_string.charAt(query_string.length - 1) === '&' ? query_string.slice(0, -1) : query_string;
};

// Convert date to string with the format of YYYY-MM-DD
window.date_to_string = date => {
  let month = String(date.getMonth() + 1).padStart(2, '0');
  let day = String(date.getDate()).padStart(2, '0');
  let year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

// Check if string format is YYYY-YYYY
window.is_valid_academic_year = academic_year => {
  let pattern = /^\d{4}-\d{4}$/;

  return pattern.test(academic_year);
};

// Check if string format is YYYY-MM-DD and if it is a valid date
window.is_valid_date = date_string => {
  let pattern = /^\d{4}-\d{2}-\d{2}$/;

  if (pattern.test(date_string)) {
    return !isNaN(new Date(date_string));
  } else {
    return false;
  }
};

// Get next element
window.next = (element, selector) => {
  const next_element = element.nextElementSibling;

  if (!selector || (next_element && next_element.matches(selector))) {
    return next_element;
  }

  return null;
};

// Offset date
window.offset_date = (old_date, offset) => {
  let new_date = new Date(old_date.getTime() + (offset * 86400000));

  return date_to_string(new_date);
};

// Remove non-URL friendly characters from URL
window.sanitize_url = url => {
  return trim_to_empty(url?.replace(/[^A-Z0-9_\-#/ ]/ig, ''));
};

// Convert string to sentence case
window.sentence_case = string => {
 return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

// Convert string to date
window.string_to_date = string => {
  let date = new Date(string);

  date.setHours(0, 0, 0, 0);

  return date;
};

// Trim string or return empty string if null
window.trim_to_empty = string => {
  return string?.trim() || '';
};

/* Form */

// Clear fields and remove validation errors
window.initialize_form = (form, clear_fields = true) => {
  let fields = form.querySelectorAll('.form-control, .form-check-input');
  let input_groups = form.querySelectorAll('.input-group');
  let checkboxes = form.querySelectorAll('input[type="checkbox"]');
  let chosen_containers = form.querySelectorAll('.chosen-container');
  let hidden_fields = form.querySelectorAll('input[type="hidden"]');
  let select_options = form.querySelectorAll('select.custom-select option, select.form-control-chosen option');
  let select_placeholders = form.querySelectorAll('select.custom-select option[value=""]');
  let submit_buttons = form.querySelectorAll('input[type="submit"]');

  fields.forEach(field => {
    if (clear_fields && !field.disabled && !field.readOnly) {
      field.value = '';
    }

    field.classList.remove('is-invalid');
  });

  chosen_containers.forEach(chosen_container => {
    chosen_container.classList.remove('is-invalid');
  });

  input_groups.forEach(input_group => {
    input_group.classList.remove('is-invalid');
  });

  submit_buttons.forEach(submit_button => {
    submit_button.disabled = false;
  });

  if (clear_fields) {
    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });

    hidden_fields.forEach(hidden_field => {
      if (hidden_field.id.includes('_init_')) {
        hidden_field.value = '';
      }
    });

    select_options.forEach(select_option => {
      select_option.selected = false;
    });

    select_placeholders.forEach(select_placeholder => {
      select_placeholder.selected = true;
    });
  }
};

// Check if form has fields with 'is-invalid' class
window.has_invalid_fields = (event, form) => {
  let invalid_fields = form.querySelectorAll('.is-invalid').length;

  if (invalid_fields) {
    event.preventDefault();
    alert('Please resolve all form errors before saving changes.');

    return true;
  } else {
    return false;
  }
};

/* Element or Field */

// Add validation message below the field
window.add_invalid_feedback = (element, message) => {
  let invalid_feedback = next(element, '.invalid-feedback') || element.parentElement.querySelector('.invalid-feedback');
  
  element.classList.add('is-invalid');

  if (invalid_feedback) {
    invalid_feedback.textContent = message;
  }
};

// Remove validation message below the field
window.remove_invalid_feedback = element => {
  let invalid_feedback = next(element, '.invalid-feedback') || element.parentElement.querySelector('.invalid-feedback');

  element.classList.remove('is-invalid');

  if (invalid_feedback) {
    invalid_feedback.textContent = '';
  }
};

// Disable and hide element, or enable and show it
window.disable_and_hide_element = (element, value) => {
  element.disabled = value;
  element.hidden = value;
};

// Disable/Enable field and set its value
window.disable_and_set_field = (element, disabled, value = '') => {
  element.disabled = disabled;
  element.value = value;
};

// Reset month, day and year fields
window.reset_date_field = element => {
  let subfields = element.querySelectorAll('input, select');

  subfields.forEach(subfield => {
    subfield.value = '';
  });

  remove_invalid_feedback(element);
};

// Empty and enable field, and remove the validation error on it
window.reset_field = element => {
  element.disabled = false;
  element.value = '';
  remove_invalid_feedback(element);
};

// Set max value of number field
window.set_max_value = (id, clear_field, ...field_ids) => {
  let element = document.querySelector(`#${id}`);
  let value = element.value;

  for (let field_id of field_ids) {
    let field = document.querySelector(`#${field_id}`);

    if (field) {
      field.max = value ? parseInt(value) : 0;
      disable_and_set_field(field, value ? false : true, clear_field ? '' : field.value);
    }
  }
};

// Toggle day field
window.toggle_day_field = (month, day, year) => {
  let special_months = [4, 6, 9, 11];
  let day29 = day.querySelector('option[value="29"]');
  let day30 = day.querySelector('option[value="30"]');
  let day31 = day.querySelector('option[value="31"]');
  let month_value = parseInt(month.value);
  let day_value = parseInt(day.value);
  let year_value = parseInt(year.value.replaceAll('y', ''));

  if (special_months.includes(month_value)) {
    disable_and_hide_element(day29, false);
    disable_and_hide_element(day30, false);
    disable_and_hide_element(day31, true);

    if (day_value === 31) {
      day.value = '';
    }
  } else if (month_value === 2) {
    disable_and_hide_element(day30, true);
    disable_and_hide_element(day31, true);

    if (year_value % 4 === 0) {
      disable_and_hide_element(day29, false);
    } else {
      disable_and_hide_element(day29, true);
    }

    if (day_value === 30 || day_value === 31 || (year_value % 4 !== 0 && day_value === 29)) {
      day.value = '';
    }
  } else {
    disable_and_hide_element(day29, false);
    disable_and_hide_element(day30, false);
    disable_and_hide_element(day31, false);
  }
};

// Toggle field
window.toggle_field = (id, ...field_ids) => {
  let element = document.querySelector(`#${id}`);

  for (let i = 0; i < field_ids.length; i++) {
    let field = document.querySelector(`#${field_ids[i]}`);
    let disabled = i === 0 ? element.classList.contains('is-invalid') : true;

    disable_and_set_field(field, disabled);
    remove_invalid_feedback(field);
  }
};

// Toggle others field
window.toggle_others_field = (element, value) => {
  reset_field(element);

  if (value === 'Others') {
    element.parentElement.hidden = false;
    element.required = true;
  } else {
    element.parentElement.hidden = true;
    element.required = false;
  }
};

// Toggle year field
window.toggle_year_field = (month, day, year) => {
  let day29 = day.querySelector('option[value="29"]');
  let month_value = parseInt(month.value);
  let day_value = parseInt(day.value);
  let year_value = parseInt(year.value.replaceAll('y', ''));

  if (year_value % 4 != 0 && month_value == 2) {
    disable_and_hide_element(day29, true);

    if (day_value == 29) {
      day.value = '';
    }
  } else {
    disable_and_hide_element(day29, false);
  }
};

/* REST Request */

window.run_request = async (id, method, path, data = null) => {
  let url = method === 'POST' ? path : `${path}/${id}`;
  let options = { method: method,
                  headers: { 'Content-Type': 'application/json',
                             'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content') } };

  if (method === 'GET' && data !== null) {
    url += `?${data_to_params(data)}`;
  }

  if (method !== 'GET') {
    options.body = data ? JSON.stringify(data) : null;
  }
  
  let results = await fetch(url, options).then(response => response.ok ? response.json() : response.text().then(text => { throw new Error(text) }))
                                         .then(results => results)
                                         .catch(error => alert(error.message));

  if (method === 'GET') {
    return results;
  } else if (results?.success) {
    alert(results.success);
    location.hash = results.anchor ? `#${results.anchor}` : '';
    location.reload();
  }
};

/* Chosen Field Validation */

// Check if at least one option has been selected
window.validate_selected_options = (id, value) => {
  let element = document.querySelector(`#${id}`);

  if (value.length === 0) {
    add_invalid_feedback(element, 'Please fill out this field.');
  } else {
    remove_invalid_feedback(element);
  }
};

/* Academic Year Field Validation */

// Compare academic year (AY) to review cycle
function compare_academic_year_to_review_cycle(label, value, start_ay, end_ay) {
  let year = parseInt(value?.substring(0, 4));
  let min_year = parseInt(start_ay?.substring(0, 4));
  let max_year = parseInt(end_ay?.substring(0, 4));

  if (end_ay && year > max_year) {
    return `${label} must be on or before the end AY of the active review cycle.`;
  } else if (start_ay && year < min_year) {
    return `${label} must be on or after the start AY of the active review cycle.`;
  } else {
    return '';
  }
}

// Compare AY1 to AY2
function compare_academic_years(comparison, label, label2, value, value2) {
  let year = parseInt(value.substring(0, 4));
  let year2 = parseInt(value2.substring(0, 4));

  if (comparison === '>' && year <= year2) {
    return `${label} must be after the ${label2}.`;
  } else if (comparison === '>=' && year < year2) {
    return `${label} must be on or after the ${label2}.`;
  } else if (comparison === '<' && year >= year2) {
    return `${label} must be before the ${label2}.`;
  } else if (comparison === '<=' && year > year2) {
    return `${label} must be on or before the ${label2}.`;
  } else {
    return '';
  }
}

// Check if AY is valid
window.validate_ay = (id, end_ay = null, start_ay = null, comparison = null, id2 = null) => {
  let element = document.querySelector(`#${id}`);
  let text = element.labels[0].textContent;
  let label = text.match(/start|end/gi) ? sentence_case(text).replace(/ay|mou/gi, x => x.toUpperCase()) : 'AY';
  let value = element.value;

  if (!is_valid_academic_year(value)) {
    add_invalid_feedback(element, 'Please input a valid AY.');
  } else {
    let message = (end_ay || start_ay) ? compare_academic_year_to_review_cycle(label, value, start_ay, end_ay) : '';
    
    if (!message && id2) {
      let element2 = document.querySelector(`#${id2}`);
      let label2 = element2.labels[0].textContent.toLowerCase().replace(/ay|mou/gi, x => x.toUpperCase());
      let value2 = element2.value;

      message = compare_academic_years(comparison, label, label2, value, value2);
    }
    
    if (message) {
      add_invalid_feedback(element, message);
    } else {
      remove_invalid_feedback(element);
    }
  }
};

/* Date Field Validation */

// Compare date to review cycle
function compare_date_to_review_cycle(label, value, start_ay, end_ay, rule) {
  let year = parseInt(value?.substring(0, 4));
  let start_year = parseInt(start_ay?.substring(0, 4));
  let end_year = parseInt(end_ay?.substring(5));

  if (rule === 1 && (year < start_year || year > end_year)) {
    return `${label} must be within the active review cycle.`;
  } else if (rule === 2 && year > end_year) {
    return `${label} must be on or before the end AY of the active review cycle.`;
  } else if (rule === 3 && year < start_year) {
    return `${label} must be on or after the start AY of the active review cycle.`;
  } else if (rule === 4 && year > (end_year + 3)) {
    return `${label} must be no later than 3 years from the end AY of the active review cycle.`;
  } else if (rule === 5 && year > (end_year + 5)) {
    return `${label} must be no later than 5 years from the end AY of the active review cycle.`;
  } else {
    return '';
  }
}

// Compare date1 to date2
function compare_dates(comparison, label, label2, value, value2) {
  let date = string_to_date(value);
  let date2 = string_to_date(value2);

  if (comparison === '>' && date <= date2) {
    return `${label} must be after the ${label2}.`;
  } else if (comparison === '>=' && date < date2) {
    return `${label} must be on or after the ${label2}.`;
  } else if (comparison === '<' && date >= date2) {
    return `${label} must be before the ${label2}.`;
  } else if (comparison === '<=' && date > date2) {
    return `${label} must be on or before the ${label2}.`;
  } else {
    return '';
  }
}

// Check if the date is valid
window.validate_date = (id, start_ay, end_ay, rule, ...date_comparisons) => {
  let element = document.querySelector(`#${id}`);
  let label = sentence_case(element.labels[0].textContent);
  let value = element.value;

  if (!is_valid_date(value)) {
    add_invalid_feedback(element, 'Please input a valid date.');
  } else {
    let message = rule ? compare_date_to_review_cycle(label, value, start_ay, end_ay, rule) : '';

    if (!message) {
      for (let [comparison, id2] of date_comparisons) {
        let element2 = document.querySelector(`#${id2}`);
        let label2 = id2 === 'current date' ? id2 : element2.labels[0].textContent.toLowerCase();
        let value2 = id2 === 'current date' ? (element?.min || element?.max) : element2.value;
        
        message = compare_dates(comparison, label, label2, value, value2);

        if (message) {
          break;
        }
      }
    }

    if (message) {
      add_invalid_feedback(element, message);
    } else {
      remove_invalid_feedback(element);
    }
  }
};

// Check if the date is not a future date
window.validate_no_future_date = id => {
  let element = document.querySelector(`#${id}`);
  let value = element.value;

  if (!is_valid_date(value)) {
    add_invalid_feedback(element, 'Please input a valid date.');
  } else {
    let date = string_to_date(value);
    let max_date = string_to_date(element.max);

    if (date > max_date) {
      add_invalid_feedback(element, 'Date must not be a future date.');
    } else {
      remove_invalid_feedback(element);
    }
  }
};

/* Others Field Validation */

window.validate_others = (select_field, others_field, label, value) => {
  let option = select_field.querySelector(`option[value="${value}"]`);

  if (value === '' || option === null) {
    remove_invalid_feedback(others_field);
  } else {
    add_invalid_feedback(others_field, `Please enter ${article(label)} ${label} that is not among the options in the ${label} field.`);
  }
};

/* URL Field Validation */

// Check if URL is a valid Google Drive URL
window.validate_gdrive_url = id => {
  let field = document.querySelector(`input[type="url"]#${id}`);
  let url = field.value;
  let pattern = /https:\/\/drive.google.com\/file\/d\/[\w_?=-]+(\/(view|edit)*\?*[\w=]+)*/;
  
  if (url.length) {
    if (!field.checkValidity()) {
      add_invalid_feedback(field, field.validationMessage);
    } else if (!pattern.test(url)) {
      add_invalid_feedback(field, 'Please input a valid Google Drive URL.');
    } else {
      remove_invalid_feedback(field);
    }
  } else {
    remove_invalid_feedback(field);
  }
};

// Check if URL is a valid HTTPS URL
window.validate_https_url = id => {
  let field = document.querySelector(`input[type="url"]#${id}`);
  let url = field.value;
  let is_valid = typeof validate({ website: url }, { website: { url: { schemes: ['https'] } } }) === 'undefined';
  
  if (url.length && !is_valid) {
    add_invalid_feedback(field, 'Please input a valid https URL.');
  } else {
    remove_invalid_feedback(field);
  }
};

// Check if URL is a valid image URL
window.validate_image_url = id => {
  let field = document.querySelector(`input[type="url"]#${id}`);
  let url = field.value;
  let is_valid = typeof validate({ website: url }, { website: { url: true } }) === 'undefined';
  let pattern = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif|png|svg))/i;

  if (url.length && (!is_valid || !pattern.test(url))) {
    add_invalid_feedback(field, 'Please input a valid image URL.');
  } else {
    remove_invalid_feedback(field);
  }
};

// Check if URL is a valid URL
window.validate_url = id => {
  let field = document.querySelector(`input[type="url"]#${id}`);
  let url = field.value;
  let is_valid = typeof validate({ website: url }, { website: { url: true } }) === 'undefined';
  
  if (url.length && !is_valid) {
    add_invalid_feedback(field, 'Please input a valid URL.');
  } else {
    remove_invalid_feedback(field);
  }
};

/* Navigation */

// Set active navigation link and tab pane, and go to anchor
window.set_anchor = () => {
  let hash = sanitize_url(window.location.hash);

  if (hash.length) {
    let active_nav_link = document.querySelector('#mini-wizard a.nav-link.active');
    let active_tab_pane = document.querySelector('.tab-pane.active.show');
    let nav_link = document.querySelector(`a.nav-link[href="${hash}"]`);
    let tab_pane = document.querySelector(`.tab-pane${hash}`);
    let flash_message = document.querySelector('#flash-message');

    active_nav_link?.classList.remove('active');
    active_tab_pane?.classList.remove('active', 'show');

    if (!hash.includes('tab')) {
      tab_pane = document.querySelector(hash).closest('.tab-pane');
      nav_link = document.querySelector(`a.nav-link[href="#${tab_pane.id}"]`);

      if (flash_message) {
        let section = document.querySelector(`${hash} .card, section${hash}`);

        section.prepend(flash_message);
        flash_message.classList.remove('d-none');
      }
    }

    nav_link.classList.add('active');
    tab_pane.classList.add('active', 'show');
    nav_link.closest('.scroll')?.scrollTo({ left: nav_link.offsetLeft - 25, behavior: 'smooth' })
    scroll_to_anchor(hash, flash_message);
  }
};

// Scroll window to anchor location
window.scroll_to_anchor = (hash, flash_message = null) => {
  let target = document.querySelector(hash);
  let offset_flash = flash_message ? 60 : 0;

  if (target) {
    setTimeout(() => {
      window.scrollTo({ top: target.offsetTop + offset_flash, behavior: 'smooth' })
    }, 100);
  }
};